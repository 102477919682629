import React, { useState } from 'react';

const MessageInput = ({ sendMessage }) => {
  const [currentMessage, setCurrentMessage] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleInputChange = (event) => {
    setCurrentMessage(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    sendMessage(currentMessage, selectedCategory); // Include the selected category in the sendMessage function
    setCurrentMessage('');
    setSelectedCategory(''); // Reset the selected category after sending the message
  };

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <textarea
          value={currentMessage}
          onChange={handleInputChange}
          placeholder="Type your message..."
          rows={5} // Adjust the number of rows as needed
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default MessageInput;