import React, { useState, useEffect } from 'react';
import './styles.css'; // Import your CSS here
import CategorySelector from './CategorySelector';
import MessageInput from './MessageInput'; // Move this import to the top
import MessageDisplay from './MessageDisplay';
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://mfixenchsjkyfrhaxjvf.supabase.co';
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY; // Use REACT_APP_ prefix
const supabase = createClient(supabaseUrl, supabaseKey);

const App = () => {
  const [messages, setMessages] = useState(() => {
    const storedMessages = localStorage.getItem('messages');
    return storedMessages ? JSON.parse(storedMessages) : [];
  });

  const [selectedCategory, setSelectedCategory] = useState('');
  const [username, setUsername] = useState('');
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [disclaimerOpen, setDisclaimerOpen] = useState(true); // New state variable for disclaimer visibility

  useEffect(() => {
    connectToMetaMask();
    fetchMessages();
  }, []);

  useEffect(() => {
    localStorage.setItem('messages', JSON.stringify(messages));
    checkUnreadMessages();
  }, [messages]);

  const connectToMetaMask = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        if (accounts.length > 0) {
          const address = accounts[0];
          setUsername(address);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error('MetaMask is not installed or not available');
    }
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const fetchMessages = async () => {
    try {
      const { data, error } = await supabase.from('messages').select('*');
      if (error) {
        console.error('Failed to fetch messages:', error);
      } else {
        setMessages(data);
      }
    } catch (error) {
      console.error('Failed to fetch messages:', error);
    }
  };

  const sendMessage = async (message) => {
    const newMessage = {
      message,
      sender: username || 'Unknown',
      walletAddress: username || '',
      timestamp: new Date().toLocaleString(),
      category: selectedCategory,
    };
  
    try {
      const { data, error } = await supabase.from('messages').insert([newMessage]);
      if (error) {
        console.error('Failed to send message:', error);
      } else {
        const insertedMessage = data[0];
        setMessages((prevMessages) => [insertedMessage, ...prevMessages]);
      }
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  const toggleDisclaimer = () => {
    setDisclaimerOpen(!disclaimerOpen); // Toggle the state
  };

  const checkUnreadMessages = () => {
    if (username) {
      const formattedUsername = username.toLowerCase();
      const unread = messages.filter(
        (message) =>
          message.sender.toLowerCase().startsWith('@') && message.sender.toLowerCase().substr(1) === formattedUsername
      );
      setUnreadMessages(unread);
    }
  };

  const handleNotificationClick = () => {
    checkUnreadMessages();
    scrollToUnread();
  };

  const scrollToUnread = () => {
    const unreadMessage = document.getElementById('unreadMessage');
    if (unreadMessage) {
      unreadMessage.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="App">
      {/* Message input area */}
      <div className="message-input-container">
        <MessageInput sendMessage={sendMessage} username={username} />
      </div>

      {unreadMessages && unreadMessages.length > 0 && (
        <div className="notification" onClick={handleNotificationClick}>
          You have {unreadMessages.length} unread message{unreadMessages.length > 1 ? 's' : ''}. Click here to view.
        </div>
      )}
      {disclaimerOpen && ( // Conditionally render the disclaimer based on disclaimerOpen state
        <div className="disclaimer">
          <h2>Disclaimer</h2>
          <p>
            This website serves as a platform for users to interact and exchange information. It is your responsibility to
            exercise caution and diligence when engaging with others. The developers of this website do not endorse or
            guarantee the accuracy, reliability, or legitimacy of the information shared by users.
          </p>
          <p>
            By using this website, you agree to take full responsibility for your actions and interactions. The developers
            shall not be held liable for any damages, losses, or consequences arising from your use of this website. We
            encourage you to report any suspicious or fraudulent activities to help maintain a safe and trustworthy community.
          </p>
          <p>
            You are solely responsible for complying with applicable laws, regulations, and terms of service while using this
            website. Any illegal, harmful, or unethical activities are strictly prohibited, and violators will be subject to
            account suspension and legal consequences.
          </p>
          <button onClick={toggleDisclaimer}>Agree to the disclaimer to close</button>
        </div>
      )}
      <div className="top-right">
        <h1>Dlist</h1>
        <p>
          Welcome, {username ? <span className="wallet-address">{username}</span> : 'Guest'}
        </p>
        <CategorySelector selectedCategory={selectedCategory} handleCategoryChange={handleCategoryChange} />
      </div>
      <div className="main-content">
        <MessageDisplay messages={messages} selectedCategory={selectedCategory} username={username} />
      </div>
    </div>
  );
};

export default App;
