import React from 'react';

const CategorySelector = ({ selectedCategory, handleCategoryChange }) => {
  const handleSelectChange = (event) => {
    const category = event.target.value;
    handleCategoryChange(category);
  };

  return (
    <div>
      <label>Select a Category: </label>
      <select value={selectedCategory} onChange={handleSelectChange}>
        <option value="">All</option>
        <option value="Chat">Chat</option>
        <option value="For Sale">For Sale</option>
        <option value="For Rent">For Rent</option>
        <option value="Handyman Needed">Handyman Needed</option>
        <option value="Skilled Tradesmen">Skilled Tradesmen</option>
        <option value="New Product">New Product</option>
        <option value="Events">Events</option>
        <option value="Hiring / jobs">Hiring / jobs</option>
        <option value="Barter">Barter</option>
        <option value="Crypto">Crypto</option>
        <option value="Miscellaneous">Miscellaneous</option>
        <option value="Wanted">Wanted</option>

      </select>
    </div>
  );
};

export default CategorySelector;
