import React, { useState, useEffect } from 'react';

const MessageDisplay = ({ messages, selectedCategory, username }) => {
  const [replyMessages, setReplyMessages] = useState(() => {
    const storedReplyMessages = localStorage.getItem('replyMessages');
    return storedReplyMessages ? JSON.parse(storedReplyMessages) : [];
  });

  const [selectedMessageId, setSelectedMessageId] = useState(null);

  useEffect(() => {
    localStorage.setItem('replyMessages', JSON.stringify(replyMessages));
  }, [replyMessages]);

  const filteredMessages = selectedCategory
    ? messages.filter((msg) => msg.category === selectedCategory)
    : messages;

  const handleReply = (messageId) => {
    setSelectedMessageId(messageId);
    const reply = prompt('Enter your reply:');
    if (reply) {
      const newReply = {
        messageId: messageId,
        replyMessage: reply,
        sender: username,
        timestamp: new Date().toLocaleString(),
      };
      setReplyMessages((prevReplyMessages) => [...prevReplyMessages, newReply]);
    }
  };

  const formatSender = (message) => {
    if (message.walletAddress && message.sender !== username) {
      return (
        <>
          {message.sender} <span className="wallet-address">({message.walletAddress})</span>
        </>
      );
    }
    return message.sender;
  };

  return (
    <div className="message-display">
      {filteredMessages.map((message) => {
        const messageReplies = replyMessages.filter((reply) => reply.messageId === message.id);
        return (
          <div key={message.id} className="message-item">
            <p>Message: {message.message}</p>
            <p>Sender: {formatSender(message)}</p>
            <p>Timestamp: {message.timestamp}</p>
            <button onClick={() => handleReply(message.id)}>bookmark</button>
            {selectedMessageId === message.id && (
              <div className="reply-item">
                <p>Note: {messageReplies[0] ? messageReplies[0].replyMessage : ''}</p>
                <p>*only you can see your notes {username}</p>
                <p>Timestamp: {messageReplies[0] ? messageReplies[0].timestamp : ''}</p>
              </div>
            )}
          </div>
        );
      }).reverse()}
    </div>
  );
};

export default MessageDisplay;
